import React from "react";
import { Col, Row, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import PerformanceReviewGoalService from "../../../services/PerformanceReviewGoalService";

const ExportGoalsButton = ({ user, showErrorNotification }) => {
  const handleExportPDF = () => {
    PerformanceReviewGoalService.exportGoalAsPDF(user?.id).catch((error) => {
      if (showErrorNotification) {
        showErrorNotification(error);
      }
    });
  };

  return (
    <Row className="mb-3">
      <Col className="text-right">
        <Button variant="outline-info" onClick={handleExportPDF}>
          <FontAwesomeIcon icon={faFileDownload} />
          <span className="ml-2">
            <FormattedMessage id="Goal.getAsPdf" />
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ExportGoalsButton;
