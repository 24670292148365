import React from "react";
import UserService from "../../services/UserService";
import PerformanceReviewGoalService from "../../services/PerformanceReviewGoalService";
import GoalTypeService from "../../services/GoalTypeService";
import ProcessUserService from "../../services/ProcessUserService";
import { ACTIVE_STATUS, INACTIVE_STATUS } from "../../constants/activeStatuses";
import {
  GOAL_CREATE_MODE,
  GOAL_DELETE_MODE,
  GOAL_UPDATE_MODE,
} from "../../constants/goalModes";
import { Breadcrumb, Button, Col, Container, Row } from "react-bootstrap";
import {
  ORGANIZATIONAL_GOAL_TYPE_ID,
  PERSONAL_DEVELOPMENT_GOAL_TYPE_ID,
  PROJECT_GOAL_TYPE_ID,
} from "../../constants/goalTypes";
import ReactHtmlParser from "react-html-parser";
import GoalModal from "../goal/GoalModal";
import GoalService from "../../services/GoalService";
import { ToastContainer } from "react-toastr";
import { withRouter } from "react-router-dom";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../common/display/Loading";
import AuthenticationService from "../../services/AuthenticationService";
import { PERMISSION_MODIFY_GOALS } from "../../constants/permissions";
import ErrorComponent from "../common/ErrorComponent";
import {
  FORBIDDEN_ERROR_CODE,
  NOT_FOUND_ERROR_CODE,
} from "../../constants/errorStatusCodes";
import CustomPopover from "../common/display/CustomPopover";
import Filter from "../common/Filter";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { PERFORMANCE_REVIEW_GOAL_IN_PROGRESS } from "../../constants/performanceReviewGoalStatus";
import ExportGoalsButton from "../common/buttons/ExportGoalsButton";
import { FormattedMessage } from "react-intl";

class UserGoalsDefinitionList extends React.Component {
  state = {
    user: undefined,
    performanceReviewGoal: undefined,
    performanceReviewId: 0,
    processId: undefined,
    goals: [],
    goalsToDisplay: [],
    goalTypes: [],
    selectedGoal: undefined,
    selectedGoalManager: undefined,
    selectedGoalType: undefined,
    showModal: false,
    goalMode: undefined,
    goalActiveStatus: ACTIVE_STATUS,
    activeGoals: 0,
    inactiveGoals: 0,
    loading: true,
    userFound: false,
    performanceReviewGoalFound: false,
    accessDenied: false,
    filteredTabs: [],
    triggerUpdate: false,
    hoverItem: { showButtonActions: false, hoverItemId: "" },
  };

  toastContainer;

  ORGANISATIONAL_GOAL_TYPE_ID = 1;

  componentDidMount() {
    UserService.getUserById(this.getId())
      .then((user) => {
        this.setState({
          user,
          userFound: true,
        });
        this.fetchResources(user.id);
      })
      .catch(() => this.setState({ loading: false }));
  }

  componentDidUpdate() {
    if (this.state.triggerUpdate === true) {
      this.handleFilterSubmit(this.state.goalActiveStatus);
      this.setState({
        triggerUpdate: false,
      });
    }
  }

  getId = () => {
    if (this.props.match.params.id) {
      return this.props.match.params.id;
    }
    return this.props.user.id;
  };

  fetchResources = (userId) =>
    Promise.all([
      PerformanceReviewGoalService.getCurrentPerformanceReviewGoalByUserId(
        userId
      ),
      GoalTypeService.getAllGoalTypes(),
    ])
      .then(([performanceReviewGoal, goalTypes]) => {
        this.setState({
          performanceReviewId: performanceReviewGoal.performanceReviewId,
        });
        Promise.all([
          ProcessUserService.getProcessUserByPerformanceReviewGoalId(
            performanceReviewGoal.performanceReviewGoalId
          ),
          PerformanceReviewGoalService.getGoalsByPerformanceReviewGoalId(
            performanceReviewGoal.performanceReviewGoalId
          ),
        ])
          .then(([processUser, goals]) => {
            this.setState({
              performanceReviewGoal,
              processId: processUser[0].process.id,
              goals,
              goalsToDisplay: goals,
              goalTypes,
              activeGoals: goals.filter((goal) => goal.active).length,
              inactiveGoals: goals.filter((goal) => !goal.active).length,
              performanceReviewGoalFound: true,
              filteredTabs: [
                {
                  value: "",
                  title: <FormattedMessage id="AllUsers.tabs.any" />,
                  count:
                    goals.filter((goal) => goal.active).length +
                      goals.filter((goal) => !goal.active).length <
                    999
                      ? goals.filter((goal) => goal.active).length +
                        goals.filter((goal) => !goal.active).length
                      : "1k+",
                },
                {
                  value: ACTIVE_STATUS,
                  title: <FormattedMessage id="AllUsers.tabs.active" />,
                  count:
                    goals.filter((goal) => goal.active).length < 999
                      ? goals.filter((goal) => goal.active).length
                      : "1k+",
                },
                {
                  value: INACTIVE_STATUS,
                  title: <FormattedMessage id="AllUsers.tabs.inactive" />,
                  count:
                    goals.filter((goal) => !goal.active).length < 999
                      ? goals.filter((goal) => !goal.active).length
                      : "1k+",
                },
              ],

              loading: false,
            });
            this.handleFilterSubmit(this.state.goalActiveStatus);
          })
          .catch(() =>
            this.setState({
              accessDenied: true,
              performanceReviewGoal,
              performanceReviewGoalFound: true,
              loading: false,
            })
          );
      })
      .catch(() =>
        this.setState({
          loading: false,
          performanceReviewFound: false,
        })
      );

  handleFilterSubmit = (goalActiveStatus) => {
    this.setState((prevState) => ({
      goalActiveStatus,
      goalsToDisplay: !goalActiveStatus
        ? prevState.goals
        : prevState.goals.filter(
            (g) => g.active === (goalActiveStatus === ACTIVE_STATUS)
          ),
    }));
  };

  handleUpdateActiveStatus = (goal) =>
    GoalService.attemptUpdateGoalById(goal.id, { active: !goal.active }).then(
      (response) => {
        if (response.ok) {
          this.toastContainer.success("Goal updated successfully.", "", {
            closeButton: true,
          });
          this.componentDidMount();
        } else {
          this.toastContainer.error("Goal cannot be updated.", "", {
            closeButton: true,
          });
        }
      }
    );

  handleOpenModal = (goalMode, goalDefinition) => {
    if (goalMode === GOAL_CREATE_MODE) {
      this.setState({
        goalMode,
        selectedGoalType: goalDefinition,
        selectedGoalManager: this.props.user,
        showModal: true,
      });
    } else if (goalMode === GOAL_UPDATE_MODE) {
      UserService.getUserById(goalDefinition.managerCreatorId).then((manager) =>
        this.setState({
          goalMode,
          selectedGoal: goalDefinition,
          selectedGoalManager: 1,
          showModal: true,
        })
      );
    } else if (goalMode === GOAL_DELETE_MODE) {
      this.setState({
        goalMode,
        selectedGoal: goalDefinition,
        showModal: true,
      });
    }
  };
  handleFilterChange = (event) => {
    this.setState({
      goalActiveStatus: event.target.value,
      triggerUpdate: true,
    });
  };

  handleCloseModal = () =>
    this.setState({
      goalMode: undefined,
      selectedGoal: undefined,
      selectedGoalType: undefined,
      showModal: false,
    });

  handleSubmit = async (goal) => {
    goal.performanceReviewId = this.state.performanceReviewId;
    if (this.state.goalMode === GOAL_DELETE_MODE) {
      await GoalService.attemptDeletingGoalById(goal.id).then((response) => {
        if (response.status !== 200) {
          this.toastContainer.error(
            "This goal is already assessed and cannot be deleted. You may disable it instead.",
            "",
            { closeButton: true }
          );
        } else {
          this.toastContainer.success(
            "The goal was deleted successfully.",
            "",
            { closeButton: true }
          );
          this.componentDidMount();
        }
      });
    } else if (
      this.state.goalMode === GOAL_CREATE_MODE ||
      this.state.goalMode === GOAL_UPDATE_MODE
    ) {
      await GoalService.attemptSavingGoal(goal).then((response) => {
        if (response.ok) {
          this.toastContainer.success("Goal is saved successfully.", "", {
            closeButton: true,
          });
          this.componentDidMount();
        } else {
          response.json().then((error) =>
            this.toastContainer.error(error.message, error.type, {
              closeButton: true,
            })
          );
        }
      });
    }
    this.fetchResources(this.getId());
  };

  getGoalGridByGoalTypeId = (goalTypeId) => {
    const currentGoalType = this.state.goalTypes.find(
      (goalType) => goalType.id === goalTypeId
    );

    const currentTypeGoals = this.state.goalsToDisplay.filter(
      (goal) => goal.goalTypeId === currentGoalType.id
    );

    return (
      <Row className="appraisal-preview" style={{ display: "flex" }}>
        <Col>
          <Row
            className="header "
            style={{ paddingTop: "22px", borderBottom: "none" }}
          >
            <Col className="goals-header" md={9}>
              <FormattedMessage id={`GoalType.${currentGoalType.id}`} />
            </Col>
            <Col>
              {currentGoalType.id !== ORGANIZATIONAL_GOAL_TYPE_ID &&
                !this.props.readOnly &&
                AuthenticationService.hasPermission(
                  this.props.user,
                  PERMISSION_MODIFY_GOALS
                ) && (
                  <Button
                    style={{
                      fontSize: "1.1rem",
                    }}
                    className="float-right"
                    variant="link"
                    onClick={() =>
                      this.handleOpenModal(GOAL_CREATE_MODE, currentGoalType)
                    }
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                    <FormattedMessage id="Button.add" />
                  </Button>
                )}
              <ExportGoalsButton
                user={this.state.user}
                className="float-right"
                showErrorNotification={() => {
                  this.toastContainer.error(
                    "Error occured while exporting goals",
                    "",
                    { closeButton: true }
                  );
                }}
              />
            </Col>
          </Row>
          <Row className="table-header">
            <Col md={11} />
            <Col className="gray-heading " md={1}>
              <FormattedMessage id="Goals.weight" />
            </Col>
          </Row>
          {currentTypeGoals.length > 0 ? (
            currentTypeGoals.map((goal) => (
              <Row
                key={goal.id}
                className={`appraisal-preview-row content ${
                  !goal.active && "goal-inactive"
                }`}
                style={{ paddingBottom: "1.5rem" }}
                onMouseOver={() =>
                  this.setState({
                    hoverItem: {
                      showButtonActions: true,
                      hoverItemId: goal.id,
                    },
                  })
                }
                onMouseLeave={() =>
                  this.setState({
                    hoverItem: { showButtonActions: false, hoverItemId: "" },
                  })
                }
              >
                <Col
                  className="read-only"
                  style={{ padding: "1rem" }}
                  md={
                    this.props.readOnly ||
                    !AuthenticationService.hasPermission(
                      this.props.user,
                      PERMISSION_MODIFY_GOALS
                    )
                      ? 11
                      : 11
                  }
                >
                  <h5>{goal.title}</h5>
                  {goal.description && (
                    <p className="goal-desc">{goal.description}</p>
                  )}
                  <Row>
                    <Col md={9} style={{ marginBottom: "0.125rem" }}>
                      <span style={{ fontStyle: "italic" }}>
                        <FormattedMessage id="Goals.kpi" />
                        :&nbsp;
                        {goal.kpi ? (
                          <>
                            {goal.kpiDescription ? (
                              <CustomPopover
                                placement="right"
                                text={ReactHtmlParser(goal.kpiDescription)}
                              >
                                <span>
                                  {goal.kpi}
                                  <FontAwesomeIcon
                                    style={{ marginLeft: "5px" }}
                                    icon={faInfoCircle}
                                  />
                                </span>
                              </CustomPopover>
                            ) : (
                              goal.kpi
                            )}
                          </>
                        ) : (
                          "--"
                        )}
                      </span>
                    </Col>
                  </Row>
                  {goal.setBy && (
                    <Row>
                      <Col style={{ paddingBottom: "0.5rem" }}>
                        <i style={{ fontSize: "0.8125rem" }}>
                          {<FormattedMessage id="Goals.setBy" />}
                          {`: `}
                          {currentGoalType.id === ORGANIZATIONAL_GOAL_TYPE_ID
                            ? "Proxiad SEE"
                            : goal.setBy}
                        </i>
                      </Col>
                    </Row>
                  )}
                  <div className="actions">
                    {this.state.hoverItem.showButtonActions &&
                      this.state.hoverItem.hoverItemId === goal.id &&
                      currentGoalType.id !== ORGANIZATIONAL_GOAL_TYPE_ID &&
                      !this.props.readOnly &&
                      AuthenticationService.hasPermission(
                        this.props.user,
                        PERMISSION_MODIFY_GOALS
                      ) && (
                        <>
                          <Button
                            style={{
                              fontSize: "1rem",
                              padding: "0",
                              paddingRight: "0.5rem",
                            }}
                            variant="link"
                            onClick={() =>
                              this.state.performanceReviewGoal
                                .performanceReviewGoalStatus ===
                              PERFORMANCE_REVIEW_GOAL_IN_PROGRESS
                                ? this.handleOpenModal(GOAL_UPDATE_MODE, goal)
                                : this.handleUpdateActiveStatus(goal)
                            }
                          >
                            <FontAwesomeIcon icon={faEdit} />
                            {this.state.performanceReviewGoal
                              .performanceReviewGoalStatus ===
                            PERFORMANCE_REVIEW_GOAL_IN_PROGRESS
                              ? "Edit"
                              : goal.active
                              ? "Deactivate"
                              : "Activate"}
                          </Button>

                          <Button
                            style={{
                              fontSize: "1rem",
                              padding: "0",
                            }}
                            variant="link"
                            onClick={() =>
                              this.handleOpenModal(GOAL_DELETE_MODE, goal)
                            }
                          >
                            <FontAwesomeIcon icon={faTrashAlt} />
                            <FormattedMessage id="Button.delete" />
                          </Button>
                        </>
                      )}
                  </div>
                </Col>
                <Col md={1} className={`weight-column full-height-centered`}>
                  {Number(goal.weight).toFixed(2)}
                </Col>
              </Row>
            ))
          ) : (
            <Col lg={12} align="center" className="mt-3">
              <h4>
                <FormattedMessage id="Goals.noFound" />
              </h4>
            </Col>
          )}
        </Col>
      </Row>
    );
  };

  render() {
    return this.state.loading ? (
      <Loading />
    ) : (
      <>
        {!this.state.userFound ? (
          <ErrorComponent
            statusCode={NOT_FOUND_ERROR_CODE}
            user={this.props.user}
          />
        ) : (
          <>
            {this.state.accessDenied ? (
              <ErrorComponent
                statusCode={FORBIDDEN_ERROR_CODE}
                user={this.props.user}
                message={`The access to this goals of ${this.state.performanceReviewGoal.targetEmployee.firstName} ${this.state.performanceReviewGoal.targetEmployee.lastName} is restricted`}
              />
            ) : (
              <Container style={{ width: "66%", paddingTop: "1.125rem" }}>
                {!this.state.performanceReviewGoalFound ? (
                  <Row className="mb-4">
                    <Col>
                      <header className="title text-center">
                        <h4>
                          <FormattedMessage id="Goals.noFoundАny" />
                        </h4>
                      </header>
                    </Col>
                  </Row>
                ) : (
                  <>
                    <ToastContainer
                      ref={(ref) => (this.toastContainer = ref)}
                      className="toast-top-right"
                    />
                    {this.props.location.state ? (
                      <>
                        <Row>
                          <Breadcrumb>
                            {this.props.location.state.managerRedirect ? (
                              <Breadcrumb.Item
                                onClick={() =>
                                  this.props.history.push("/manager")
                                }
                              >
                                <FormattedMessage id="EditUserForm.manager" />
                              </Breadcrumb.Item>
                            ) : (
                              <>
                                <Breadcrumb.Item
                                  onClick={() =>
                                    this.props.history.push("/reviews")
                                  }
                                >
                                  <FormattedMessage id="Breadcrumb.review" />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                  onClick={() => this.props.history.go(-2)}
                                >
                                  {this.props.location.state.reviewTitle}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                  onClick={() => this.props.history.go(-1)}
                                >
                                  {this.props.location.state.processTitle}
                                </Breadcrumb.Item>
                              </>
                            )}
                            <Breadcrumb.Item
                              active
                            >{`${this.state.user.firstName.toUpperCase()} ${this.state.user.lastName.toUpperCase()}`}</Breadcrumb.Item>
                          </Breadcrumb>
                        </Row>
                        {/*  <Row>
                          <Col>
                            {<FormattedMessage id="Goals.position" />}
                            {`: ${this.state.performanceReviewGoal.targetEmployeeTitle}`}
                          </Col>
                        </Row> */}
                        <Row>
                          <Col>
                            {<FormattedMessage id="EditUserForm.manager" />}
                            {`: ${this.state.user.managerName || "N/A"}`}
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row className="mb-4">
                        <Col>
                          <header className="title text-center">
                            {this.state.user.id !== this.props.user.id ? (
                              <>
                                <h4>{`Goals List for ${this.state.user.firstName}  ${this.state.user.lastName}`}</h4>
                                <h5>{`Manager: ${this.state.user.managerName}`}</h5>
                              </>
                            ) : (
                              <>
                                <h4>
                                  <FormattedMessage id="Goals.myGoals" />
                                </h4>
                                {this.state.performanceReviewGoal && (
                                  <p
                                    style={{
                                      color: "#838383",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {new Date(
                                      this.state.performanceReviewGoal.performanceReviewStart
                                    )
                                      .toLocaleDateString("en-GB")
                                      .replaceAll("/", ".")}
                                    {" - "}
                                    {new Date(
                                      this.state.performanceReviewGoal.performanceReviewEnd
                                    )
                                      .toLocaleDateString("en-GB")
                                      .replaceAll("/", ".")}
                                  </p>
                                )}
                              </>
                            )}
                          </header>
                        </Col>
                      </Row>
                    )}
                    <Row className="my-3">
                      <Col>
                        {this.state.user && (
                          <>
                            {!this.props.readOnly &&
                              AuthenticationService.hasPermission(
                                this.props.user,
                                PERMISSION_MODIFY_GOALS
                              ) && (
                                <Filter
                                  value={this.state.goalActiveStatus}
                                  items={this.state.filteredTabs}
                                  onClick={this.handleFilterChange}
                                />
                              )}

                            {this.getGoalGridByGoalTypeId(
                              ORGANIZATIONAL_GOAL_TYPE_ID
                            )}
                            {this.getGoalGridByGoalTypeId(PROJECT_GOAL_TYPE_ID)}
                            {this.getGoalGridByGoalTypeId(
                              PERSONAL_DEVELOPMENT_GOAL_TYPE_ID
                            )}
                          </>
                        )}
                      </Col>
                    </Row>

                    <GoalModal
                      showModal={this.state.showModal}
                      handleClose={this.handleCloseModal}
                      handleSubmit={this.handleSubmit}
                      mode={this.state.goalMode}
                      goal={this.state.selectedGoal}
                      user={this.state.user}
                      performanceReviewGoal={this.state.performanceReviewGoal}
                      manager={this.state.selectedGoalManager}
                      goalType={this.state.selectedGoalType}
                    />
                  </>
                )}
              </Container>
            )}
          </>
        )}
      </>
    );
  }
}

export default withRouter(UserGoalsDefinitionList);
