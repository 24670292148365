import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, Modal, Button } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import TrainingsService from "../../../services/TrainingsService";
import Loading from "../../common/display/Loading";
import TeamTrainingEmployees from "../TeamTraining/TeamTrainingEmployees";
import DebouncedSearchInput from "../../common/form/DebouncedSearchInput";
import Filter from "../../common/Filter";
import Pagination from "../../admin/Pagination";
import PerformanceReviewService from "../../../services/PerformanceReviewService";
import { useParams } from "react-router-dom";

const TeamTrainings = ({ user }) => {
  const intl = useIntl();
  const tabValue = "all";
  const { performanceReviewId } = useParams();
  const trainingStatuses = [
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Requested",
      }),
      value: "Requested",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.In.Progress",
      }),
      value: "In Progress",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Vendor.Selected",
      }),
      value: "Vendor Selected",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Provided",
      }),
      value: "Provided",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Internal.Done",
      }),
      value: "Internal Done",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Cancelled.by.Employee",
      }),
      value: "Cancelled by Employee",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Cancelled.by.P.and.C",
      }),
      value: "Cancelled by P and C",
    },
    {
      label: intl.formatMessage({
        id: "TrainingStatus.Left.the.Company",
      }),
      value: "Left the Company",
    },
  ];

  const [employeesTrainings, setEmployeesTrainings] = useState([]);
  const [filters, setFilters] = useState({
    employeeName: null,
    refTrainingId: null,
    trainingStatus: null,
    categoryId: null,
  });
  const [totalTrainingsCount, setTotalTrainingsCount] = useState(0);
  const [categories, setCategories] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [showAddTrainingModal, setShowAddTrainingModal] = useState(false);
  const [performanceReview, setPerformanceReview] = useState();

  useEffect(() => {
    if (performanceReviewId) {
      PerformanceReviewService.getPerformanceReviewById(
        performanceReviewId
      ).then((performanceReviewData) => {
        setPerformanceReview(performanceReviewData);
      });
      TrainingsService.getCategories().then((categories) => {
        setCategories(categories);
      });
    }
  }, [user.id, performanceReviewId]);

  useEffect(() => {
    if (performanceReview) {
      TrainingsService.getTeamTrainings(
        user.id,
        performanceReview.id,
        currentPage,
        filters
      ).then((employeesTrainings) => {
        setEmployeesTrainings(employeesTrainings.content);
        setTotalPages(employeesTrainings.totalPages);
        setTotalTrainingsCount(employeesTrainings.totalElements);
        setIsLoading(false);
      });
    }
  }, [performanceReview, user.id, currentPage, filters]);

  const toggleAddTrainingModal = () => {
    setShowAddTrainingModal(!showAddTrainingModal);
  };

  const paginationClickHandler = (event) => {
    if (event.target.text === undefined) {
      return;
    } else {
      setCurrentPage(parseInt(event.target.text) - 1);
    }
  };

  const onNameValueChange = (value) => {
    const nameValue = value === "" ? null : value.toLowerCase();
    setCurrentPage(0);
    setFilters((prevFilters) => ({
      ...prevFilters,
      employeeName: nameValue,
    }));
  };

  const onCategoryChange = (event) => {
    setCurrentPage(0);
    const categoryId =
      event.target.value === "any" ? null : Number(event.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      refTrainingId: null,
      categoryId: categoryId,
    }));
    if (categoryId !== null) {
      TrainingsService.searchRefTraining(true, categoryId).then((trainings) => {
        setTrainings([]);
        setTrainings(trainings);
      });
    } else {
      setTrainings([]);
    }
  };

  const onTrainingValueChange = (event) => {
    setCurrentPage(0);
    const refTrainingId =
      event.target.value === "any" ? null : Number(event.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      refTrainingId: refTrainingId,
    }));
  };

  const onStatusValueChange = (event) => {
    setCurrentPage(0);
    const trainingStatus =
      event.target.value === "any" ? null : event.target.value;
    setFilters((prevFilters) => ({
      ...prevFilters,
      trainingStatus: trainingStatus,
    }));
  };

  const renderFilters = () => (
    <Row className="pt-2">
      <Col>
        <DebouncedSearchInput
          name="name"
          placeholder="Name"
          id="name-control"
          size="sm"
          type="text"
          onChange={onNameValueChange}
        />
      </Col>
      <Col>
        <Form.Control
          id="role-control"
          as="select"
          name="role"
          size="sm"
          className="filter-item"
          onChange={onCategoryChange}
        >
          <option key={"any"} value={"any"}>
            {intl.formatMessage({ id: "Filter.any.category" })}
          </option>
          {categories.map((value, index) => (
            <option key={index} value={value.id} id={index}>
              {value.category}
            </option>
          ))}
        </Form.Control>
      </Col>
      <Col>
        <Form.Control
          id="role-control"
          as="select"
          name="role"
          size="sm"
          className="filter-item"
          onChange={onTrainingValueChange}
        >
          <option key={"any"} value={"any"}>
            {intl.formatMessage({ id: "Filter.any.training" })}
          </option>
          {trainings.map((value, index) => (
            <option key={index} value={value.id} id={index}>
              {value.label}
            </option>
          ))}
        </Form.Control>
      </Col>
      <Col>
        <Form.Control
          id="team-control"
          as="select"
          name="role"
          size="sm"
          className="filter-item"
          onChange={onStatusValueChange}
        >
          <option key={"any"} value={"any"}>
            {intl.formatMessage({ id: "Filter.any.status" })}
          </option>
          {trainingStatuses.map((status, index) => (
            <option key={index} value={status.value} id={index}>
              {status.label}
            </option>
          ))}
        </Form.Control>
      </Col>
    </Row>
  );

  const handleExport = () =>
    TrainingsService.exportAsExcel(currentPage, performanceReviewId, filters);

  return isLoading ? (
    <Loading />
  ) : (
    <Container>
      <Form.Group>
        {performanceReview && (
          <>
            <div style={{ color: "var(--primary-light)" }}>
              {intl.formatMessage({ id: "Trainings.label" }) +
                performanceReview.title}
            </div>
            <Row className="mt-2">
              <Col>
                <Filter
                  value={tabValue}
                  items={[
                    {
                      value: tabValue,
                      title: intl.formatMessage({
                        id: "All.employees.label",
                      }),
                      count: totalTrainingsCount,
                    },
                  ]}
                />
              </Col>
              <Col md={6}>
                <Button
                  className="float-right"
                  variant="link"
                  style={{ fontSize: "1.1rem" }}
                  onClick={handleExport}
                >
                  <FontAwesomeIcon icon={faFileExport} />
                  {<FormattedMessage id="Training.button.export" />}
                </Button>
              </Col>
            </Row>
            {renderFilters()}
          </>
        )}
        {isLoading ? (
          <Loading />
        ) : employeesTrainings.length > 0 ? (
          <>
            <Row className="mt-3">
              <TeamTrainingEmployees employeesTrainings={employeesTrainings} />
            </Row>
            <Pagination
              currentPage={currentPage}
              paginationClickHandler={paginationClickHandler}
              setCurrentPage={setCurrentPage}
              pages={totalPages}
            />
          </>
        ) : (
          <Row className="mt-3">
            <Col sm={6}>
              <FormattedMessage id="Manager.noEmployeesToShow" />
            </Col>
          </Row>
        )}
        <Modal
          size="xl"
          show={showAddTrainingModal}
          onHide={toggleAddTrainingModal}
          centered
        ></Modal>
      </Form.Group>
    </Container>
  );
};

export default TeamTrainings;
